import React, { Component } from "react";
import MPSBarLoader from "../../../components/MPSBarLoader";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import SurveyGroupView from "./SurveyGroupView";
import ModalPopup from "../../../components/ModalPopup";
import history from "../../../history";
import {
  validateQuestion,
  getSurveyDataReadyForSubmission,
  setDataInGroup,
  getReferenceRanges,
} from "./Utils";
import Text from "../../../components/Text";
import Messages from "../../Messages";
import libraryService from "../../../services/contentLibraryService";
import ArticlePreview from "../../ContentLibrary/Preview/ArticlePreview";
import I18nProvider from "../../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

export function setInitialValueToValue(survey) {
  if (!survey) {
    return;
  }
  let groups = survey.items;
  groups.forEach((group) => {
    let questions = group.items;
    questions.forEach((question) => {
      let answers = question.answers || [];
      if (question.initialValue) {
        answers[0] = question.initialValue;
      } else {
        let min = question.scale ? question.scale.minimum : null;
        if (question.type === "CHOICE_WITH_SCALE" && (min || min == 0)) {
          answers[0] = {
            valueInteger: min,
            score: min,
          };
        }
      }
      question.answers = answers;
    });
  });
  return survey;
}

class SurveyRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: this.props.survey,
      currentGroup: 0,
      invalidAnswer: false,
      invalidField: "",
      showLib: false,
      library: null,
      formatted: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ survey: nextProps.survey });
  }

  setData = (gIndex, qIndex, value) => {
    const newState = this.checkAssociatedQuestions(gIndex, qIndex, value);
    this.setState({
      ...newState,
      survey: {
        ...newState.survey,
        items: setDataInGroup(newState.survey.items, gIndex, qIndex, value),
      },
      error: false
    });
  };
  setRanges = (rMin, rMax) => {
    this.setState({ refRangeMin: rMin, refRangeMax: rMax });
  };

  startSubmitting = () => {
    this.setState({ error: false });

    let valid = this.validateQuestions();
    if (valid) {
      if (this.props.embedded) {
        this.submitAnswers("COMPLETED");
      } else {
        this.setState({ submitConfirmation: true });
      }
    } else {
      this.setState({ invalidAnswer: true });
    }
  };

  closeSubmitting = () => {
    this.setState({ submitConfirmation: false });
  };

  submitAnswers = (status) => {
    let { survey } = this.state;
    getSurveyDataReadyForSubmission(survey);
    this.props
      .submitAnswers({
        referencedSurveyId: survey.referencedSurveyId || survey.id,
        items: survey.items,
        provStatus: status
      })
      .then((response) => {
        this.closeSubmitting();
      });
  };

  goToPrevGroup = () => {
    this.setState({ currentGroup: this.state.currentGroup - 1 });
  };

  goToNextGroup = () => {
    let valid = this.validateQuestions();
    if (valid) {
      this.setState({
        currentGroup: this.state.currentGroup + 1,
        error: false,
      });
    }
  };

  validateQuestions = () => {
    this.setState({ error: null, pefError: null, fevError: null });

    let currentGroup = this.state.currentGroup;
    let { survey } = this.state;
    let groups = survey.items;
    let group = { ...groups[currentGroup] };
    let questions = [...group.items];
    let valid = true;
    let invalidQ = "";
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i];
      valid = valid && validateQuestion(survey, group, question, i, this);
      if (!valid && Object.hasOwn(question, "answers")) {
        const [refMin, refMax] = getReferenceRanges(survey, group, question);
        if (
          (question.type === "INTEGER" || question.type === "DECIMAL") &&
          refMin !== Number.MAX_VALUE
        ) {
          this.setRanges(refMin, refMax);
        } else {
          this.setRanges(null, null);
        }
        this.setState({ invalidAnswer: true });
        this.setState({ invalidField: question.text });
        return false;
      } else if (!valid) {
        invalidQ = question.text;
      }
    }
    this.setState({ invalidAnswer: false });
    this.setState({ invalidField: invalidQ });
    return valid;
  };

  cancel = () => {
    history.goBack();
  };

  showLib = (libId) => {
    this.setState({ showLib: true});
    libraryService.loadArticle(libId).then((article) => {
      this.setState({ library: article});
    });
  };

  hideLib = () => {
    this.setState({showLib : false, library: false})
  }

  checkAssociatedQuestions = (groupIndex, questionIndex, value) => {
    let refresh = null;

    const { survey, refreshQuestions } = this.state;
    const currentGroup = this.state.currentGroup || 0;
    const answerType = survey.items[groupIndex]?.items[questionIndex];
    if (answerType && answerType.isMultipleAnswersPermitted) return {...this.state, refreshQuestions: refresh};
    const newSurveyGroups = survey.items.map((svGroup, svGroupIndex) => {
      const newSurveyQuestions = svGroup.items.map((svQuestion, svQuestionIndex) => {
        if (svQuestion.enableWhen?.question) {
          const currentAnswer =
          groupIndex >= 0
            ? { ...survey.items[groupIndex]?.items[questionIndex], ...value }
            : {};
          const { question, answerString } = svQuestion.enableWhen;
          if (question === currentAnswer.itemKey) {
            const isSameAnswer = currentAnswer.valueString === answerString;
            if (!isSameAnswer) {
              delete svQuestion.answers;
              refresh = { gIndex: svGroupIndex, qIndex: svQuestionIndex, value: {valueString: "", valueAsString: ""} }
            }
            
            return { ...svQuestion, hide: !isSameAnswer };
          }
          if (!currentAnswer.valueString && !refreshQuestions) {
            return { ...svQuestion, hide: true }
          }
          return svQuestion;
        }
        return { ...svQuestion, hide: false };
      });
      return {...svGroup, items: [...newSurveyQuestions]};
    });

    const skipNextGroup = newSurveyGroups[currentGroup + 1]?.items.filter(
      (item) => !item.hide
    ).length < 1;

    const response = {
      survey: {
        ...survey,
        items: [...newSurveyGroups],
      },
      formatted: true,
      skipNextGroup,
      refreshQuestions: refresh
    };

    if (value) {
      return response;
    } else {
      this.setState(response);
    }

  };

  componentDidUpdate() {
    if (this.props.survey && !this.state.formatted) {
      this.checkAssociatedQuestions();
    }
    if (this.state.refreshQuestions) {
      this.setData(
        this.state.refreshQuestions.gIndex,
        this.state.refreshQuestions.qIndex,
        this.state.refreshQuestions.value
      );
    }
  }

  render() {
    let { loading, submitting, embedded, popUp, previewMode } = this.props;
    let {
      survey,
      currentGroup,
      submitConfirmation,
      error,
      pefError,
      fevError,
      showLib,
      library,
      skipNextGroup
    } = this.state;
    let associatedLibrary = survey?.associatedLibrary;
    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!survey) {
      return null;
    }

    let groups = survey.items;
    let noOfGroups = groups?.length;

    const isLastGroup = currentGroup === noOfGroups - 1;
    const hideNextGroup = isLastGroup || skipNextGroup;

    return (
      <div>
        {/*<h5 className="my-2 mb-4">Complete Survey</h5>*/}
        {!embedded && !popUp && (
          <MPSButton type="link" onClick={this.cancel} className="text-right">
            <Text content={Messages.cancel} />
          </MPSButton>
        )}
        <div className="row justify-content-center">
        {embedded ? (
          <div
            style={{
              fontWeight: 600,
              fontSize: "0.9rem",
            }}
            className="text-center my-2 mb-4"
          >
            {survey.title}
          </div>
        ) : (
          <h5 className="text-center mb-2">{survey.title}</h5>
        )}
        {associatedLibrary && <MPSButton
                onClick={() => this.showLib(survey.associatedLibrary)}
                style={{
                  width: "20px", 
                  height: "20px", 
                  borderRadius: "50%", 
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "10px",
                  marginLeft: "10px",
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                }}
              >
                ?
              </MPSButton>}
              </div>
        <div className="row no-gutters">
          <div
            className={
              embedded
                ? "col-md-12 col-sm-12"
                : "col-md-8 offset-md-2 col-sm-12"
            }
          >
            {/* {this.state.invalidAnswer ? (
              <p style={{ color: "red" }}>
                Please enter a valid answer for {this.state.invalidField}
              </p>
            ) : null} */}
            {/* <p
              style={{
                color: this.state.invalidAnswer ? "red" : "white",
                opacity: this.state.invalidAnswer ? "100%" : "0%",
              }}
            >
              Please enter a valid answer for {this.state.invalidField}{" "}
              {this.state.refRangeMin !== null &&
                `(${this.state.refRangeMin} - 
                  ${this.state.refRangeMax})`}
            </p> */}
            {!submitting && <div>
              <SurveyGroupView
                group={groups[currentGroup]}
                gIndex={currentGroup}
                setData={this.setData}
                embedded={embedded}
                validateQuestions={this.validateQuestions}
                previewMode={previewMode}
              />
            </div>}

            {!previewMode && <div className="text-center my-1">
              {error && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.answer_mandatory_qs} />
                </div>
              )}

              {pefError && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.value_bw_100and800} />
                </div>
              )}

              {fevError && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.value_bw_1and6} />
                </div>
              )}
            </div>}

            {!previewMode && <div className="d-flex justify-content-between align-items-center my-2">
              {!submitting && currentGroup > 0 && (
                <MPSButton
                  color="primary"
                  size={"md"}
                  type="block"
                  className={`text-capitalize mr-2 ${
                    embedded ? "" : "font-weight-bold"
                  }`}
                  onClick={this.goToPrevGroup}
                >
                  <Text content={Messages.back} />
                </MPSButton>
              )}

              {currentGroup === 0 && <div />}

              <div>
              {!submitting && survey.allowDrafts &&  (
                    <MPSButton
                      color="primary"
                      className="text-capitalize font-weight-bold mr-3"
                      onClick={() => this.submitAnswers("IN_PROGRESS")}
                    >
                      <Text content={Messages.save} />
                      {" "}
                      <Text content={Messages.draft} />
                    </MPSButton>
                  )}
                {!submitting && (
                  <MPSButton
                    color="primary"
                    size="md"
                    className={`text-capitalize ${
                      embedded ? "" : "font-weight-bold"
                    }`}
                    onClick={
                      hideNextGroup
                        ? this.startSubmitting
                        : this.goToNextGroup
                    }
                  >
                    {hideNextGroup ? (
                      <Text content={Messages.submit_assessment} />
                    ) : (
                      <Text content={Messages.next} />
                    )}
                  </MPSButton>
                )}
                {submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>}
          </div>
        </div>

        {submitConfirmation && !previewMode && (
          <ModalPopup
            title={<Text content={Messages.submit_survey_q}/>}
            onClose={this.closeSubmitting}
          >
            <p>
              <Text content={Messages.sure_submit_survey} />
            </p>

            <div className="d-flex justify-content-end align-items-center">
              <a onClick={this.closeSubmitting} className="text-cancel">
                <Text content={Messages.cancel} />
              </a>

              <div>
                {!submitting && survey.allowDrafts &&  (
                  <MPSButton
                    color="primary"
                    className="text-capitalize font-weight-bold mr-3"
                    onClick={() => this.submitAnswers("IN_PROGRESS")}
                  >
                    <Text content={Messages.save} />
                    {" "}
                    <Text content={Messages.draft} />
                  </MPSButton>
                )}
                {!submitting && (
                  <MPSButton
                    color="primary"
                    className="text-capitalize font-weight-bold"
                    onClick={() => this.submitAnswers("COMPLETED")}
                  >
                    <Text content={Messages.submit} />
                  </MPSButton>
                )}

                {submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </ModalPopup>
        )}
        <div>
          {showLib && !previewMode && (
            <ModalPopup onClose={this.hideLib}>
              <ArticlePreview article={library} embed/>
            </ModalPopup>
          )}
        </div>
      </div>
    );
  }
}

export default SurveyRenderer;
